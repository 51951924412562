import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Grid, Tooltip } from '@material-ui/core'
import { Button, Menu, MenuItem, Typography, Alert, Toast } from '@refera/ui-web'
import moment from 'moment'
import { useParams } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'

import DropdownIcon from '_assets/icons/ic-dropdown.svg'
import IconButton from '_components/svg/icon-button'
import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'
import Select from '_components/select'
import { getMessageSubjectOptions } from '_modules/messages/actions'
import { getServiceOrder } from '_modules/service-orders/actions'
import useFetchCall from '_hooks/use-fetch-call'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'
import { ROUTES } from '_utils/constants'
import { UPDATE_AVAILABILITY, editHelpRequest } from '_modules/helpRequests/actions'
import { getHelpRequestsByIDSelector } from '_modules/helpRequests/selectors'
import ShowToIntermediaryDialog from '../modals/show-to-intermediary-dialog'
import ProcedureDetailsDialog from '../modals/procedure-details-dialog'
import StatusCell from '../status-cell'
import { useToast } from '_/hooks/use-toast'

import { FROM_TO_OPTIONS, MENU, MENU_VALUES } from './constants'
import useStyles from './styles'

const subjectToAllowRoleMap = {
  tradesman_to_refera: 'allowTradesman',
  intermediary_to_refera: 'allowCsmanagerCsapprover',
  refera_to_intermediary: 'allowCsrefera',
}

const ProcedureOverview = () => {
  const styles = useStyles()
  const { id: helpRequestId } = useParams()
  const { isAdmin } = useRolePermission()
  const dispatch = useDispatch()

  const helpRequest = useSelector(getHelpRequestsByIDSelector(helpRequestId))

  const [anchorEl, setAnchorEl] = useState(null)
  const [openTooltip, setOpenTooltip] = useState(null)
  const [messageSubjectOptions, setMessageSubjectOptions] = useState(null)
  const [modalShowToIntermediayOpen, toggleModalShowToIntermediayOpen] = useToggle()
  const [modalPendingOpen, toggleModalPendingOpen] = useToggle()
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [toastMessage, setToastMessage] = useState('')
  const [subject, setSubjectseleted] = useState('')
  const { showToast } = useToast()

  const isResolved = useMemo(
    () => helpRequest?.datetimeResolved !== null,
    [helpRequest?.datetimeResolved]
  )

  const serviceOrderId = useMemo(
    () => helpRequest?.serviceOrder,
    [helpRequest?.serviceOrder, helpRequest]
  )

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOpenShowToIntermediary = useCallback(() => {
    handleCloseMenu()
    toggleModalShowToIntermediayOpen()
  }, [handleCloseMenu])

  const handleOpenTooltip = useCallback(() => {
    setOpenTooltip(true)
  }, [])

  const handleCloseTooltip = useCallback(() => {
    setOpenTooltip(false)
  }, [])

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget)
      handleCloseTooltip()
    },
    [handleCloseTooltip]
  )

  const isDatetimeResolvedValid = useMemo(() => {
    const datetimeResolved = new Date(helpRequest?.datetimeResolved)
    const FIVE_DAYS_IN_MS = 5 * 24 * 60 * 60 * 1000 // 5 dias em milissegundos
    const now = new Date()

    return (
      !helpRequest?.datetimeResolved || datetimeResolved.getTime() + FIVE_DAYS_IN_MS > now.getTime()
    )
  }, [helpRequest?.datetimeResolved])

  const useTradesmanToReferaMenuItem = useMemo(() => {
    const isHelpRequestFromTradesmanToRefera =
      helpRequest?.typeSubject === 'help_request' && helpRequest?.fromTo === 'tradesman_to_refera'

    if (isAdmin && isHelpRequestFromTradesmanToRefera) {
      const menuItemText = helpRequest?.intermediaryAvailable
        ? 'Ocultar pedido para intermediária'
        : 'Exibir pedido para intermediária'

      return <MenuItem onClick={handleOpenShowToIntermediary}>{menuItemText}</MenuItem>
    }

    return null
  }, [isAdmin, helpRequest])

  const handleAddComment = useCallback(() => {
    if (isDatetimeResolvedValid) {
      toggleModalPendingOpen()
      return
    }

    setModalDialog({
      isOpen: true,
      subTitle: `Você não pode realizar essa ação em um item de ajuda já resolvido. Favor prosseguir criando um novo item de ajuda para o chamado.`,
      type: WARNING_MODAL,
    })
  }, [isDatetimeResolvedValid])

  const menuItems = useMemo(() => {
    if (isAdmin) {
      return MENU
    }

    return MENU.filter(item => !item?.isAdmin)
  }, [isAdmin])

  const onSuccessSubmit = useCallback(() => {
    showToast({ type: 'success' })
  }, [])

  const onChangeSubjects = useCallback(
    event => {
      const selectedValue = event.target.value
      setSubjectseleted(selectedValue)
      const payload = {
        subject: selectedValue,
      }
      dispatch(editHelpRequest(helpRequestId, payload)).then(() => {
        onSuccessSubmit()
      })
    },
    [helpRequestId]
  )

  const fetchData = useCallback(async () => {
    const serviceOrderResponse = await dispatch(getServiceOrder(serviceOrderId))
    if (serviceOrderResponse) {
      const params = {
        stepStatus:
          helpRequest?.typeSubject === 'help_request' ? serviceOrderResponse?.stepStatus : '',
        typeSubject: helpRequest?.typeSubject,
      }
      const response = await dispatch(getMessageSubjectOptions(params))
      setMessageSubjectOptions(response)
    }
  }, [dispatch, serviceOrderId])

  useEffect(() => {
    setSubjectseleted(helpRequest?.subject)
    if (serviceOrderId) {
      fetchData()
    }
  }, [helpRequest?.subject, serviceOrderId, fetchData])

  const filteredOptions = useMemo(() => {
    const filtered = (messageSubjectOptions || []).filter(option => {
      if (helpRequest?.typeSubject === 'help_request') {
        const allowRole = subjectToAllowRoleMap[helpRequest?.fromTo]
        return !allowRole || option[allowRole]
      }
      return option.typeDelayId === helpRequest?.typeDelay
    })

    return filtered.map(option => ({
      value: option?.status,
      label: option?.option,
    }))
  }, [messageSubjectOptions, helpRequest?.fromTo, helpRequest?.typeSubject, helpRequest?.typeDelay])

  const getMenuInfo = useCallback(
    menuValue => {
      switch (menuValue) {
        case MENU_VALUES.CREATED_AT:
          return helpRequest ? moment(helpRequest[menuValue]).format('DD/MM/yyyy') : ''
        case MENU_VALUES.FROM_TO:
          return helpRequest ? FROM_TO_OPTIONS[helpRequest[menuValue]] : ''
        case MENU_VALUES.SUBJECT_TRANSLATED:
          return (
            <Select
              key={menuValue}
              className={styles.select}
              options={filteredOptions}
              value={subject || ''}
              disabled={!isAdmin || isResolved}
              onChange={onChangeSubjects}
            />
          )
        case MENU_VALUES.EXPECTED_DEADLINE:
          return helpRequest ? moment(helpRequest[menuValue]).format('DD/MM/yyyy') : ''
        default:
          return helpRequest?.[menuValue] || ''
      }
    },
    [helpRequest, isResolved, messageSubjectOptions, subject]
  )

  const renderMenuInfo = useCallback(
    menuItem => {
      switch (menuItem?.value) {
        case MENU_VALUES.SERVICE_ORDER:
          return (
            <Typography variant="h6" font="dmsans" className={styles.idField} noWrap>
              {getMenuInfo(menuItem.value)}
            </Typography>
          )
        case MENU_VALUES.STATUS:
          return <StatusCell isResolved={isResolved} />
        default:
          return (
            <Typography variant="h6" font="dmsans" className={styles.menuItemValue} noWrap>
              {getMenuInfo(menuItem?.value)}
            </Typography>
          )
      }
    },
    [getMenuInfo, isResolved, styles.menuItemValue, styles.idField]
  )

  const handleCloseToast = useCallback(() => setToastMessage(''), [])

  useFetchCall(
    UPDATE_AVAILABILITY.ACTION,
    () => {
      setToastMessage('Ação realizada com sucesso')
      toggleModalShowToIntermediayOpen()
    },
    () => {
      setToastMessage('Ocorreu um erro')
    }
  )

  return (
    <>
      <Grid className={styles.menuContainer}>
        <Grid className={styles.menuItems}>
          {menuItems.map(menuItem => (
            <Grid className={styles.menuItem} key={menuItem.value}>
              <Typography color="black" variant="h6" font="dmsans">
                {menuItem.label}
              </Typography>
              {renderMenuInfo(menuItem)}
            </Grid>
          ))}
        </Grid>
        <Grid className={styles.menuButtons}>
          <Button variant="primary" color="primary" onClick={handleAddComment}>
            Adicionar trâmite
          </Button>
          <Tooltip title="Opções" placement="bottom" open={openTooltip}>
            <span>
              <div
                onMouseOver={handleOpenTooltip}
                onFocus={handleCloseTooltip}
                onMouseLeave={handleCloseTooltip}
              >
                <IconButton icon={DropdownIcon} onClick={handleClick} />
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  style: {
                    marginTop: 10,
                    marginLeft: isAdmin ? '-9%' : '-8%',
                  },
                }}
              >
                <Grid>
                  <MenuItem
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${ROUTES.SERVICE_ORDER}/${helpRequest?.serviceOrder}`}
                  >
                    Ver chamado
                  </MenuItem>
                  <MenuItem
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${ROUTES.SERVICE_ORDER}/${helpRequest?.serviceOrder}/orcamento/${helpRequest?.budget}`}
                    disabled={!helpRequest?.budget}
                  >
                    Ver o orçamento
                  </MenuItem>
                  {useTradesmanToReferaMenuItem}
                </Grid>
              </Menu>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <ProcedureDetailsDialog
        onCancel={toggleModalPendingOpen}
        open={modalPendingOpen}
        title="Adicionar trâmite"
      />
      <ShowToIntermediaryDialog
        open={modalShowToIntermediayOpen}
        onCancel={toggleModalShowToIntermediayOpen}
        value={helpRequest?.intermediaryAvailable}
      />
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />
      <Toast
        draggable
        open={toastMessage.length > 1}
        autoHideDuration={6000}
        onClose={handleCloseToast}
      >
        <Alert
          severity={toastMessage.includes('sucesso') ? 'success' : 'error'}
          title={toastMessage}
          onClose={handleCloseToast}
        />
      </Toast>
    </>
  )
}

export default ProcedureOverview
